import Web3 from "web3";

import "./App.css";

import "./assets/css/animate.css";
import "./assets/css/owl.carousel.css";
import "./assets/css/owl.theme.css";
import "./assets/css/owl.transitions.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/jquery.countdown.css";

import "./assets/css/style.css";
import "./assets/css/de-grey.css";
import "./assets/css/colors/schema-01.css";
import "./assets/css/coloring.css";

import "./i18n/config";

import { FC, lazy, useEffect } from "react";
import { Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

const Home = lazy(() => import("./pages/Home"));
const Explore = lazy(() => import("./pages/Explore"));
const Zone = lazy(() => import("./pages/Zone"));
const Land = lazy(() => import("./pages/Land"));

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

const App: FC = () => {
  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert("Not supported");
    }
    console.log(window.web3);
  }

  useEffect(() => {
    //loadWeb3();
  }, []);

  return (
    <div className="dark-scheme de-grey">
      <div id="wrapper">
        <Header></Header>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/explore" component={Explore} />
        <Route exact path="/zone" component={Zone} />
        <Route exact path="/land" component={Land} />
        <Footer></Footer>
      </div>
    </div>
  );
};

export default App;
