import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { walletInfoState } from "../../recoil/atom";
import { useRecoilState } from "recoil";
import Web3 from "web3";
import { ellipsisString } from "../../util/common";
import { WalletInfo } from "../../typings/model";

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

const Wallet: FC = () => {
  const { t, i18n } = useTranslation();
  const [walletInfo, setwalletInfo] =
    useRecoilState<WalletInfo>(walletInfoState);
  const [account, setAccount] = useState<string>("");

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert("Not supported");
    }

    const web3 = window.web3;

    const accounts = await web3.eth.getAccounts();
    const balance = await web3.eth.getBalance(accounts[0]);
    const balanceunit = await web3.utils.fromWei(balance);

    setAccount(ellipsisString(accounts[0]));
    setwalletInfo({ account: accounts[0], balance: balanceunit });
  };

  useEffect(() => {
    //loadWeb3();
    //loadBlockchainData2();
  }, []);

  return (
    <div className="menu_side_area">
      <a href="#" onClick={loadWeb3} className="btn-main btn-wallet">
        <i className="icon_wallet_alt"></i>
        <span>{account || t("navbar.connectWallet")}</span>
      </a>
      <span id="menu-btn"></span>
    </div>
  );
};

export default Wallet;
