import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Wallet from "../Wallet";

const Header: FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <header className="transparent scroll-dark">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex sm-pt10">
              <div className="de-flex-col">
                <div className="de-flex-col">
                  <div id="logo">
                    <Link to="/">
                      <img alt="" src="images/logo-jk-coin.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="de-flex-col header-col-mid">
                <ul id="mainmenu">
                  <li>
                    <Link to="/">
                      {t("navbar.home")} <span></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/explore"}>
                      {t("navbar.buyLand")}
                      <span></span>
                    </Link>
                  </li>
                  <li>
                    <a href="03_grey-author.html">
                      {t("navbar.sellLand")}
                      <span></span>
                    </a>
                  </li>
                  <li>
                    <a href="03_grey-author.html">
                      {t("navbar.aboutUs")}
                      <span></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {t("navbar.contactUs")}
                      <span></span>
                    </a>
                  </li>
                </ul>
                <Wallet></Wallet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
