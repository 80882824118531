import { atom } from "recoil";
import { WalletInfo } from "../typings/model";

export const loadingState = atom<boolean>({
  key: "loadingState",
  default: false,
});

export const languageState = atom<string>({
  key: "language",
  default: "en",
});

export const walletInfoState = atom<WalletInfo>({
  key: "walletInfo",
  default: { account: "", balance: 0 },
});
